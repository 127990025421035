// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-how-to-use-beautify-code-js": () => import("./../src/pages/how-to-use/beautify-code.js" /* webpackChunkName: "component---src-pages-how-to-use-beautify-code-js" */),
  "component---src-pages-how-to-use-customized-highlight-color-js": () => import("./../src/pages/how-to-use/customized-highlight-color.js" /* webpackChunkName: "component---src-pages-how-to-use-customized-highlight-color-js" */),
  "component---src-pages-how-to-use-excel-numbers-google-srpeadsheet-to-evernote-table-js": () => import("./../src/pages/how-to-use/excel-numbers-google-srpeadsheet-to-evernote-table.js" /* webpackChunkName: "component---src-pages-how-to-use-excel-numbers-google-srpeadsheet-to-evernote-table-js" */),
  "component---src-pages-how-to-use-index-js": () => import("./../src/pages/how-to-use/index.js" /* webpackChunkName: "component---src-pages-how-to-use-index-js" */),
  "component---src-pages-how-to-use-markdown-themes-js": () => import("./../src/pages/how-to-use/markdown-themes.js" /* webpackChunkName: "component---src-pages-how-to-use-markdown-themes-js" */),
  "component---src-pages-how-to-use-resize-images-to-same-width-js": () => import("./../src/pages/how-to-use/resize-images-to-same-width.js" /* webpackChunkName: "component---src-pages-how-to-use-resize-images-to-same-width-js" */),
  "component---src-pages-how-to-use-three-steps-to-get-started-js": () => import("./../src/pages/how-to-use/three-steps-to-get-started.js" /* webpackChunkName: "component---src-pages-how-to-use-three-steps-to-get-started-js" */),
  "component---src-pages-how-to-use-transform-url-to-url-with-title-js": () => import("./../src/pages/how-to-use/transform-url-to-url-with-title.js" /* webpackChunkName: "component---src-pages-how-to-use-transform-url-to-url-with-title-js" */),
  "component---src-pages-how-to-use-use-markdown-in-evernote-js": () => import("./../src/pages/how-to-use/use-markdown-in-evernote.js" /* webpackChunkName: "component---src-pages-how-to-use-use-markdown-in-evernote-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../src/templates/FaqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-how-to-template-js": () => import("./../src/templates/HowToTemplate.js" /* webpackChunkName: "component---src-templates-how-to-template-js" */),
  "component---src-templates-troubleshooting-template-js": () => import("./../src/templates/TroubleshootingTemplate.js" /* webpackChunkName: "component---src-templates-troubleshooting-template-js" */)
}

